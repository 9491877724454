/**
 * @name 로그관련유틸함수
 */
import styled from 'styled-components';
import {CSS} from 'types';

export const trace = (message: any, style?: CSS) => {
  console.log(`%c${message}`, `${style}`);
};
/**
 * @name 로그관련유틸함수
 * @param message
 */
export const log = (message: string | number | {} | [], css?: any) => {
  if (process.env.NODE_ENV !== 'development') return;
  const styles = {
    base: ['color: #888', 'font-size:12px', 'padding: 0 4px'],
    string: ['padding: 2px 4px', 'color: #111', 'background-color: orange'],
    object: ['padding: 0', 'font-size:11px', 'font-weight:normal'],
    number: ['background-color: green']
  };
  // 스타일링있을때
  // if (css) {
  //   console.log(`%c${message}`, css);
  //   return;
  // }
  let style = styles.base.join(';') + ';';
  // 문자열
  if (typeof message === 'string') {
    style += styles.string.join(';') + ';';
    // console.log(`%c${message}`, style);
  } else {
    style += styles.object.join(';') + ';';
    // console.log(`%c${JSON.stringify(message, null, 2)}`, style);
  }
};
// 로그색상사용
log.red = (message: string | number | {} | [], css?: any) => {
  log(message, 'color: red;');
};
log.blue = (message: string | number | {} | [], css?: any) => {
  log(message, 'color: blue;');
};
log.green = (message: string | number | {} | [], css?: any) => {
  log(message, 'color: teal;');
};
/**@name 디버그화면 */
export const debug = (message: any): React.ReactNode => {
  return (
    <Content>
      <pre className="wrapper">
        <p className="title">
          typeof: <span>{typeof message}</span>
        </p>
        <div className="message">{JSON.stringify(message, null, 1)}</div>
      </pre>
    </Content>
  );
};

//*--------------------------------------------------*
const Content = styled.section`
  margin: 10px auto;
  padding: 0 10px;
  border: 2px dotted #000;
  font-size: 14px;
  background: olive;
  box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.3);
  p.title {
    // margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }
  p.title span {
    color: #111;
    font-weight: bold;
    text-decoration: underline;
  }
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    letter-spacing: -0.2px;
    .message {
      padding: 5px;
      font-size: 12px;
      line-height: 14px;
    }
    /* .message:hover {
      background: #5c5c01;
    } */
  }
  div.row {
    margin: 0;
    padding: 4px 20px;
    font-size: 12px;
    border-bottom: 1px solid #aaa;
    &:nth-child(2n-1) {
      background: #ccc;
    }
    &:nth-child(2n) {
      background: #eee;
    }
  }
  &.trace {
    padding: 0;
    border: 1px solid #111;
    background-color: #fff;
    box-shadow: none;
  }
`;
