/**
 * @name 장치구성요소(장비)
 * @description
 */
import styled from 'styled-components';
import {Form} from '.';
import {Events, useAxios, useObject} from 'lib';
import {useSiteAddStore} from 'pages/site-manage/model';
import {useEffect} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {API} from 'config';
// contents
import {Submit} from './index';
import {systemEquipmentProps} from 'pages/site-manage/model/type.device';

const Contents = (): JSX.Element => {
  // store
  const {itemSelected, siteId} = useSiteAddStore();
  const [info, setInfo, infoReset] = useObject();
  const queryClient = useQueryClient();
  const {post, put} = useAxios();
  const {mutate} = useMutation({
    mutationFn: async () => {
      let res;

      if (info.siteDeviceNo) {
        res = await put(`${API}/site/devices/${info.siteDeviceNo}`, info);
      } else {
        res = await post(`${API}/site/devices?siteId=${siteId}`, info);
      }

      if (res?.success) return res?.data;
      throw new Error(res?.error?.message);
    },
    onSuccess: () => {
      // 개별로 query를 무효화한다.
      Events.emit('toast');
      queryClient.invalidateQueries({queryKey: ['equipment']});
      queryClient.invalidateQueries({queryKey: ['module']});
      queryClient.invalidateQueries({queryKey: ['facilities']});
    },
    onError: (error) => {
      Events.emit('toast', error?.message || '저장이 실패하였습니다 관리자에게 문의바랍니다.');
    }
  });

  useEffect(() => {
    infoReset();
    const item = itemSelected as systemEquipmentProps;

    if (item.siteDeviceNo) {
      setInfo(item);
    } else {
      setInfo({...item, siteId: siteId, deviceType: item?.name});
    }
  }, [itemSelected]);

  return (
    <Content>
      <Form
        name="name"
        value={info?.name}
        title="장비 표시명"
        required
        placeholder="장비 표시명을 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        title="IP"
        mode="IP"
        name="ipAddress"
        value={info?.ipAddress}
        required
        placeholder="IP를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        name="port"
        value={info?.port}
        title="Port"
        mode="NUMBER"
        required
        placeholder="장비 표시명을 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        name="slaveId"
        value={info?.slaveId}
        title="슬레이브 ID"
        mode="NUMBER"
        placeholder="슬레이브 ID를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        name="sn"
        value={info?.sn}
        title="시리얼 번호"
        placeholder="시리얼 번호를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        name="instLocation"
        value={info?.instLocation}
        title="위치"
        placeholder="위치를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        name="macAddress"
        value={info?.macAddress}
        title="Mac"
        placeholder="Mac를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        name="description"
        value={info?.description}
        title="메모"
        placeholder="내용을 입력하세요.."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      {/* 취소,추가 */}
      <Submit onSubmit={() => mutate()} />
      {/* {debug(info)} */}
    </Content>
  );
};
export default Contents;

const Content = styled.section``;
