/**
 * @name SignIn
 * @description
 */
import styled from 'styled-components';
import {Color} from 'styles';
// contents
import {Visual, Form} from './contents/';
import {SESSION_STORAGE, useRoutes} from 'lib';
import {MENU} from 'config';

export default function Presenter() {
  // hooks
  //*--------------------------------------------------*
  const {navigate} = useRoutes();
  return (
    <Content>
      {/* {debug(mode)} */}
      <section className="visual">
        <Visual />
      </section>
      <section className="form">
        <Form />
        {/* <button
          onClick={async () => {
            sessionStorage.setItem(
              SESSION_STORAGE.TOKEN,
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZGVhM2FlMmJmMDI5ZDkyODQ5NTZkYSIsInVzZXJuYW1lIjoi6rmA64-E7JqwIiwiZW1haWwiOiJkb3dvb0Bjcm9jdXNlbmVyZ3kuY29tIiwicGhvdG8iOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NLOEgxSXo3bm0xUDN6enNzQVZ0aDBRRlh3QmpNWlVzMVhRYWwyajNGYmN0LTNPRFE9czk2LWMiLCJpYXQiOjE3MzczMzQxODIsImV4cCI6MTczNzkzODk4Mn0.n3FHa7Zf_c3LoreoXx8XCuKznOfBnahPSF4dQsT_Shc'
            );
            navigate(MENU.DASHBOARD);
          }}>
          토큰로그인
        </button> */}
      </section>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  section {
    display: flex;
    height: calc(100vh - 64px);
    justify-content: center;
    align-items: center;
    /* <!--비쥬얼영역 --> */
    &.visual {
      margin-left: 32px;
      flex: 2;
      background-color: ${Color.blue100};
    }

    /* <!--폼영역 --> */
    &.form {
      flex: 1;
    }
  }
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 800px) {
    .visual {
      display: none;
    }
  }
`;
